import Button from "../../../Buttons/Button";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Column, Label, Line } from "../../../../style";
import { secondary } from "../../../../theme";
import { useApi } from "../../../../hooks/api";
import { useGlobal } from "../../../../hooks/global";
import { ModalLoading } from "../../../Loadings/ModalLoading";
import { ContainerButtons, ContainerForm, ContainerTextArea, Form, TextArea, VariableButton } from "./style";
import './style.css';
import { FixRequiredSelect } from "../../../Inputs/BaseSelects/FixRequiredSelect";
import { html } from 'js-beautify';
import Input from "../../../Inputs/Input";
import { Editor } from '@tinymce/tinymce-react';

interface IFormMessageModelModalProps {
  onCancel: Function;
  onSave: Function;
  defaultData?: {
    id: string;
    title: string;
    subject: string;
    type: string;
    text: string;
    name: string;
  } | null;
}

const formatForWhatsApp = (html: string) => {
  let text = html;

  text = text.replace(/<(\/)?strong>/g, '*');

  text = text.replace(/<(\/)?em>/g, '_');

  text = text.replace(/<(\/)?s>/g, '~');

  text = text.replace(/<\/?[^>]+(>|$)/g, "");

  return text;
};

const type_options = [
  { label: 'WhatsApp', value: 'wpp' },
  { label: 'E-mail', value: 'email' }
];

const visualizations_modes_options = [
  { label: 'HTML', value: 'html' },
  { label: 'Editor de texto', value: 'text' }
]

const ICONS_MAPPER: any = {
  reservation: '🏨',
  accommodation: '🏠',
  'check-in': '🔑',
  guest: '🧑',
  general: '📅'
}

const VARIABLES = [
  { label: 'Código da Reserva', value: 'reservaCodigo', type: 'reservation' },
  { label: 'Data de Entrada', value: 'reservaDataEntrada', type: 'reservation' },
  { label: 'Data de Saída', value: 'reservaDataSaida', type: 'reservation' },
  { label: 'Status de Pagamento', value: 'reservaStatusPagamento', type: 'reservation' },
  { label: 'Status de Check-in', value: 'reservaStatusCheckin', type: 'reservation' },
  { label: 'Origem da Reserva', value: 'reservaOrigem', type: 'reservation' },
  { label: 'Data de Criação da Reserva', value: 'reservaDataCriacao', type: 'reservation' },
  { label: 'Data de Criação do Check-in', value: 'checkinDataCriacao', type: 'check-in' },
  { label: 'Senha da Acomodação', value: 'checkinSenhaAcomodacao', type: 'check-in' },
  { label: 'Senha da Área de Acesso', value: 'checkinSenhaAreaAcesso', type: 'check-in' },
  { label: 'Código da Acomodação', value: 'acomodacaoCodigo', type: 'accommodation' },
  { label: 'Nome da Acomodação', value: 'acomodacaoNome', type: 'accommodation' },
  { label: 'Localização da Acomodação', value: 'acomodacaoLocalizacao', type: 'accommodation' },
  { label: 'Tipo de Acesso da Acomodação', value: 'acomodacaoTipoAcesso', type: 'accommodation' },
  { label: 'Fechadura da Acomodação', value: 'acomodacaoFechadura', type: 'accommodation' },
  { label: 'Áreas de Acesso da Acomodação', value: 'acomodacaoAreasAcesso', type: 'accommodation' },
  { label: 'Primeiro nome do hóspede titular', value: 'hospedePrimeiroNomeTitular', type: 'guest' },
  { label: 'Nome do hóspede titular', value: 'hospedeNomeTitular', type: 'guest' },
  { label: 'E-mail do hóspede titular', value: 'hospedeEmailTitular', type: 'guest' },
  { label: 'CPF do hóspede titular', value: 'hospedeCPFTitular', type: 'guest' },
  { label: 'Passaporte do hóspede titular', value: 'hospedePassaporteTitular', type: 'guest' },
  { label: 'Telefone do hóspede titular', value: 'hospedeTelefoneTitular', type: 'guest' },
  // { label: 'Links dos documentos do hóspede titular', value: 'hospedeLinksDocsTitular', type: 'guest' },
  { label: 'Todos os dados dos hóspedes', value: 'hospedeTodos', type: 'guest' },
  { label: 'Data de hoje', value: 'hoje', type: 'general' },
  { label: 'Dia atual', value: 'diaAtual', type: 'general' },
  { label: 'Dia atual por extenso', value: 'diaAtualExtenso', type: 'general' },
  { label: 'Mês atual', value: 'mesAtual', type: 'general' },
  { label: 'Mês atual por extenso', value: 'mesAtualExtenso', type: 'general' },
  { label: 'Ano atual', value: 'anoAtual', type: 'general' },
  { label: 'Ano atual por extenso', value: 'anoAtualExtenso', type: 'general' },
]

function formatHTML(htmlString: string): string {
  return html(htmlString, { indent_size: 4, space_in_empty_paren: true });
}

export const FormMessageModel: React.FC<IFormMessageModelModalProps> = (props) => {
  const { onSave, defaultData } = props;

  const { theme, notify, notifyBottom } = useGlobal();
  const { api } = useApi();
  const [visualizationMode, setVisualizationMode] = useState('text');
  const [subject, setSubject] = useState(defaultData?.subject ?? "");
  const [name, setName] = useState(defaultData?.name ?? "");
  const [message, setMessage] = useState(defaultData?.text ?? "");
  const [type, setType] = useState(defaultData?.type ?? 'email');
  const [_firstRender, setFirstRender] = useState(true);
  const [nameError, setNameError] = useState<string | null>(null);

  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const [loadingCreate, setLoadingCreate] = useState(false);

  const onSubmit = useCallback(async () => {
    setLoadingCreate(true);

    try {
      const form_data = { subject, type, text: message, name }

      if (defaultData?.id) {
        await api.put(`/message-models/${defaultData?.id}`, form_data);
      } else {
        await api.post("/message-models", form_data);
      }

      notify(`Modelo de mensagem ${defaultData?.id ? "atualizado" : "criado"} com sucesso!`, "success");

      onSave();
    } catch (err: any) {
      const message = err?.response?.data?.detail ?? "Erro ao criar o modelo de mensagem!";
      if (message === "Nome já em uso") setNameError("Nome já em uso");
      else notify(message, "error");
    }

    setLoadingCreate(false);
  }, [subject, type, message, name, api]);

  const handleStopPropagation = useCallback(
    (e: any) => {
      e?.preventDefault();
      e?.stopPropagation();
      const confirm = window.confirm("Deseja realmente salvar o modelo?");
      if (confirm) onSubmit();
    },
    [onSubmit, api]
  );

  const handleChange = (value: string) => setMessage(value);

  const copyText = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        notifyBottom("Variável copiada para área de tranferência!", "success");
      })
      .catch((err) => {
        notifyBottom("Erro ao copiar a variável para a área de tranferência!", "error");
      });
  };

  const handleKeyDownInTextArea = (e: any) => {
    if (e.key === 'Tab') {
      e.preventDefault();

      const textarea = e?.target;
      const start = textarea?.selectionStart;
      const end = textarea?.selectionEnd;

      textarea.value = textarea?.value?.substring(0, start) + '\t' + textarea?.value?.substring(end);

      textarea.selectionStart = textarea.selectionEnd = start + 4;
    }
  }

  useEffect(() => {
    setFirstRender((prev) => {
      if (prev) return false

      setMessage('')

      if (type === 'wpp') {
        setVisualizationMode('text')
        setSubject('')
      }

      return true
    })
  }, [type])

  useEffect(() => {
    if (visualizationMode === 'html') setMessage((prev) => formatHTML(prev ?? ''))
  }, [visualizationMode])

  useEffect(() => { setNameError(null) }, [name])

  return (
    <>
      <Column style={{ flex: 1, overflow: "auto", flexDirection: "row", gap: 16, padding: 16, flexWrap: 'wrap' }}>
        <ContainerForm open style={{ flex: 4, paddingBottom: 0, minWidth: 300 }}>
          <Form onSubmit={handleStopPropagation}>
            <Column style={{ gap: 16 }}>
              <Column style={{ gap: 16 }}>
                <Line style={{ gap: 16, flexWrap: 'wrap' }}>
                  <Column style={{ flex: 1 }}>
                    <Label>Nome*</Label>
                    <Input
                      noUpperCase={false}
                      required
                      value={name}
                      error={nameError}
                      onChange={(e: any) => setName(e)}
                    />
                  </Column>
                  <Column style={{ flex: 1 }}>
                    <Label>Tipo*</Label>
                    <FixRequiredSelect
                      placeholder=""
                      options={type_options}
                      defaultValue={type_options.find((item) => item.value === type)}
                      onChange={(value: any) => setType(value?.value)}
                    />
                  </Column>
                  <Column style={{ flex: 1 }}>
                    <Label>Modo de visualização*</Label>
                    <FixRequiredSelect
                      placeholder=""
                      isDisabled={type === 'wpp'}
                      options={visualizations_modes_options}
                      value={visualizations_modes_options.find((item) => item.value === visualizationMode)}
                      onChange={(value: any) => setVisualizationMode(value?.value)}
                    />
                  </Column>
                </Line>
                {type === 'email' && (
                  <Line style={{ gap: 16 }}>
                    <Column style={{ flex: 1 }}>
                      <Label>Assunto*</Label>
                      <Input
                        noUpperCase
                        required
                        value={subject}
                        onChange={(e: any) => setSubject(e)}
                      />
                    </Column>
                  </Line>
                )}
              </Column>
              <Column style={{ gap: 5 }}>
                <Label>Mensagem*</Label>
                {visualizationMode === 'html' && (
                  <TextArea
                    theme={theme}
                    value={message}
                    onKeyDown={handleKeyDownInTextArea}
                    onChange={(e) => setMessage(e?.target?.value)}
                    placeholder="Edite seu html manualmente aqui..."
                  />
                )}
                {visualizationMode === 'text' && (
                  <ContainerTextArea theme={theme}>
                    {type === 'email' && (
                      <Editor
                        apiKey="bypgaef6jor1xklepivwa1iftz9u5o1bhm004dd1dvo7tkx2"
                        value={message}
                        init={{
                          height: 'calc(100vh - 403px)',
                          language: 'pt_BR',
                          menubar: false,
                          plugins: 'advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount',
                          toolbar: 'fontsize fontfamily | bold italic underline strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist table | link image fullscreen ',
                          resize: false,
                        }}
                        onEditorChange={handleChange}
                      />
                    )}
                    {type === 'wpp' && (
                      <Editor
                        apiKey="bypgaef6jor1xklepivwa1iftz9u5o1bhm004dd1dvo7tkx2"
                        value={message}
                        init={{
                          height: 'calc(100vh - 325px)',
                          language: 'pt_BR',
                          menubar: false,
                          end_container_on_empty_block: true,
                          plugins: "fullscreen paste",
                          toolbar: 'bold italic strikethrough | fullscreen',
                          resize: false,
                        }}
                        onEditorChange={handleChange}
                      />
                    )}
                  </ContainerTextArea>
                )}
              </Column>
            </Column>
            <button
              className="display-none"
              type="submit"
              ref={submitButtonRef}
            />
          </Form>
        </ContainerForm>
        <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
          <div style={{ display: "flex", flexDirection: "column", gap: 10, minWidth: 300, flex: 1 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ fontWeight: "bold" }}>Variáveis</div>
              <div style={{ fontSize: 14 }}>
                Clique em uma variável para copiar a chave correspondente.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 10, padding: 10, overflow: "auto", marginLeft: -10 }}>
              {VARIABLES.map((v) => (
                <VariableButton onClick={() => copyText(`{{${v.value}}}`)} key={v.value} theme={theme}>
                  {ICONS_MAPPER[v.type]} {v.label}
                </VariableButton>
              ))}
            </div>
          </div>
        </div>
      </Column>
      <ContainerButtons theme={theme}>
        <Button
          onClick={() => submitButtonRef.current?.click()}
          background={secondary[theme]}
          color={"#FFF"}
          style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
          children={"Salvar"}
        />
      </ContainerButtons>

      <ModalLoading loading={loadingCreate} theme={theme} />
    </>
  );
};
