import { maskFunctions } from "../services/maskServices";
import { copyOf, correctDate2 } from "../services/generalServices";
import {
  IMapDefaultFormProps,
  IMapFieldsProps,
  IMapOptionsProps,
} from "../interfaces";
import {
  accountTypesOptions,
  banksOptions,
  destinationsOptions,
  documentsTypesOptions,
  isManagerOptions,
  localizationsOptions,
  maritalStatusOptions,
  paymentTypesOptions,
  personsTypesOptions,
  planIconsOptions,
  readjustmentIndicesOptions,
  responsibleTypeOptions,
  securityTypeOptions,
  warrantyOptions,
} from "./options";

export const personDefaultForm: IMapFieldsProps[] = [
  { keys: ["id"], name: "id" },
  { keys: ["creci"], name: "creci" },
  { keys: ["documents"], name: "documents" },
  {
    keys: ["spouse"],
    name: "spouse_id",
    valueKeys: ["id"],
    labelKeys: ["registration_data", "name"],
  },
  {
    keys: ["broker"],
    name: "broker_id",
    valueKeys: ["id"],
    labelKeys: ["registration_data", "name"],
  },
  {
    keys: ["representatives"],
    name: "representatives",
    valueKeys: ["id"],
    labelKeys: ["registration_data", "name"],
  },
  {
    keys: ["payment", "type"],
    name: "payment_type",
    options: paymentTypesOptions,
  },
  { keys: ["payment", "bank_code"], name: "bank_code", options: banksOptions },
  { keys: ["payment", "agency"], name: "agency" },
  { keys: ["payment", "account"], name: "account" },
  {
    keys: ["payment", "account_type"],
    name: "account_type",
    options: accountTypesOptions,
  },
  { keys: ["payment", "operation"], name: "operation" },
  { keys: ["payment", "in_name"], name: "in_name" },
  {
    keys: ["payment", "cpf_cnpj"],
    name: "cpf_cnpj",
    mask: maskFunctions.cpfOrCnpj.mask,
  },
  {
    keys: ["address", "city"],
    name: "city_id",
    valueKeys: ["id"],
    labelKeys: ["name"],
  },
  {
    keys: ["address", "state"],
    name: "state_id",
    valueKeys: ["id"],
    labelKeys: ["name"],
  },
  { keys: ["address", "cep"], name: "cep", mask: maskFunctions.cep.mask },
  { keys: ["address", "district"], name: "district" },
  { keys: ["address", "public_place"], name: "public_place" },
  { keys: ["address", "complement"], name: "complement" },
  { keys: ["address", "number"], name: "number" },
  {
    keys: ["registration_data", "type"],
    name: "type",
    options: personsTypesOptions,
  },
  { keys: ["registration_data", "name"], name: "name" },
  { keys: ["registration_data", "corporate_name"], name: "corporate_name" },
  { keys: ["registration_data", "fantasy_name"], name: "fantasy_name" },
  {
    keys: ["registration_data", "cnpj"],
    name: "cnpj",
    mask: maskFunctions.cnpj.mask,
  },
  {
    keys: ["registration_data", "phone"],
    name: "phone",
    mask: maskFunctions.phone.mask,
  },
  {
    keys: ["registration_data", "state_registration"],
    name: "state_registration",
  },
  {
    keys: ["registration_data", "municipal_registration"],
    name: "municipal_registration",
  },
  {
    keys: ["registration_data", "marital_status"],
    name: "marital_status",
    options: maritalStatusOptions,
  },
  { keys: ["registration_data", "birth"], name: "birth", mask: correctDate2 },
  {
    keys: ["registration_data", "cpf"],
    name: "cpf",
    mask: maskFunctions.cpf.mask,
  },
  { keys: ["registration_data", "rg"], name: "rg" },
  { keys: ["registration_data", "issuing_body"], name: "issuing_body" },
  { keys: ["registration_data", "nationality"], name: "nationality" },
  { keys: ["registration_data", "profession"], name: "profession" },
  { keys: ["user", "email"], name: "email" },
];

export const userModalDefaultForm: IMapFieldsProps[] = [
  { keys: ["id"], name: "id" },
  { keys: ["surname"], name: "surname" },
  { keys: ["email"], name: "email" },
  {
    keys: ["user_type"],
    name: "user_type_id",
    valueKeys: ["id"],
    labelKeys: ["name"],
  },
];

export const franchiseDefaultForm: IMapFieldsProps[] = [
  { keys: ["id"], name: "id" },
  { keys: ["email"], name: "email" },
  {
    keys: ["registration_data", "type"],
    name: "type",
    options: personsTypesOptions,
  },
  { keys: ["registration_data", "corporate_name"], name: "corporate_name" },
  { keys: ["registration_data", "fantasy_name"], name: "fantasy_name" },
  {
    keys: ["registration_data", "cnpj"],
    name: "cnpj",
    mask: maskFunctions.cnpj.mask,
  },
  {
    keys: ["registration_data", "phone"],
    name: "phone",
    mask: maskFunctions.phone.mask,
  },
  {
    keys: ["registration_data", "state_registration"],
    name: "state_registration",
  },
  {
    keys: ["registration_data", "municipal_registration"],
    name: "municipal_registration",
  },
  {
    keys: ["address", "city"],
    name: "city_id",
    valueKeys: ["id"],
    labelKeys: ["name"],
  },
  {
    keys: ["address", "state"],
    name: "state_id",
    valueKeys: ["id"],
    labelKeys: ["name"],
  },
  { keys: ["address", "cep"], name: "cep", mask: maskFunctions.cep.mask },
  { keys: ["address", "district"], name: "district" },
  { keys: ["address", "public_place"], name: "public_place" },
  { keys: ["address", "complement"], name: "complement" },
  { keys: ["address", "number"], name: "number" },
  {
    keys: ["responsible"],
    name: "responsible_id",
    valueKeys: ["id"],
    labelKeys: ["registration_data", "name"],
  },
];

export const creditPolicyDefaultForm: IMapFieldsProps[] = [
  { keys: ["id"], name: "id" },
  { keys: ["name"], name: "name" },
  { keys: ["description"], name: "description" },
  { keys: ["type"], name: "type", options: personsTypesOptions },
  { keys: ["executors"], name: "executors", isJson: true },
];

export const propertyDefaultForm: IMapFieldsProps[] = [
  { keys: ["id"], name: "id" },
  { keys: ["iptu"], name: "iptu" },
  { keys: ["friendly_id"], name: "friendly_id" },
  {
    keys: ["rent_value"],
    name: "rent_value",
    mask: maskFunctions.currency.mask,
  },
  {
    keys: ["condo_value"],
    name: "condo_value",
    mask: maskFunctions.currency.mask,
  },
  {
    keys: ["iptu_value"],
    name: "iptu_value",
    mask: maskFunctions.currency.mask,
  },
  { keys: ["is_managed"], name: "is_managed", options: isManagerOptions },
  { keys: ["managed_type"], name: "managed_type" },
  { keys: ["managed_value"], name: "managed_value" },
  { keys: ["rental_fee_type"], name: "rental_fee_type" },
  { keys: ["rental_fee_value"], name: "rental_fee_value" },
  { keys: ["water"], name: "water" },
  { keys: ["electricity"], name: "electricity" },
  { keys: ["gas"], name: "gas" },
  { keys: ["metreage"], name: "metreage", mask: maskFunctions.m2.mask },
  {
    keys: ["localization_type"],
    name: "localization_type",
    options: localizationsOptions,
  },
  {
    keys: ["destination_type"],
    name: "destination_type",
    options: destinationsOptions,
  },
  {
    keys: ["broker"],
    name: "broker_id",
    valueKeys: ["id"],
    labelKeys: ["registration_data", "name"],
  },
  {
    keys: ["property_type"],
    name: "property_type_id",
    valueKeys: ["id"],
    labelKeys: ["name"],
  },
  {
    keys: ["address", "city"],
    name: "city_id",
    valueKeys: ["id"],
    labelKeys: ["name"],
  },
  {
    keys: ["address", "state"],
    name: "state_id",
    valueKeys: ["id"],
    labelKeys: ["name"],
  },
  { keys: ["address", "cep"], name: "cep", mask: maskFunctions.cep.mask },
  { keys: ["address", "district"], name: "district" },
  { keys: ["address", "public_place"], name: "public_place" },
  { keys: ["address", "complement"], name: "complement" },
  { keys: ["address", "number"], name: "number" },
  { keys: ["owners"], name: "owners" },
];

export const plansDefaultForm: IMapFieldsProps[] = [
  { keys: ["id"], name: "id" },
  { keys: ["name"], name: "name" },
  { keys: ["icon"], name: "icon", options: planIconsOptions },
  { keys: ["value"], name: "value", mask: maskFunctions.currency.mask },
  { keys: ["frequency"], name: "frequency" },
  { keys: ["recurrence"], name: "recurrence" },
  { keys: ["users_limit"], name: "users_limit" },
  { keys: ["cloud_limit"], name: "cloud_limit" },
  { keys: ["contract_templates_limit"], name: "contract_templates_limit" },
  {
    keys: ["pj_unit_value"],
    name: "pj_unit_value",
    mask: maskFunctions.currency.mask,
  },
  {
    keys: ["pf_unit_value"],
    name: "pf_unit_value",
    mask: maskFunctions.currency.mask,
  },
  {
    keys: ["doc_unit_value"],
    name: "doc_unit_value",
    mask: maskFunctions.currency.mask,
  },
  { keys: ["permissions"], name: "permissions", isJson: true },
  { keys: ["description"], name: "description" },
];

export const documentsTypesDefaultForm: IMapFieldsProps[] = [
  { keys: ["id"], name: "id" },
  { keys: ["name"], name: "name" },
  { keys: ["size"], name: "size" },
  { keys: ["type"], name: "type", options: documentsTypesOptions },
  { keys: ["extensions"], name: "extensions", isJson: true },
];

export const warrantyDefaultForm: IMapFieldsProps[] = [
  { keys: ["application_number"], name: "application_number" },
  { keys: ["background_type"], name: "background_type" },
  { keys: ["bank"], name: "bank" },
  { keys: ["date_end"], name: "date_end" },
  { keys: ["date_init"], name: "date_init" },
  { keys: ["description"], name: "description" },
  { keys: ["identification"], name: "identification" },
  { keys: ["insurance_company"], name: "insurance_company" },
  { keys: ["number_of_installments"], name: "number_of_installments" },
  { keys: ["observations"], name: "observations" },
  { keys: ["policy"], name: "policy" },
  { keys: ["value"], name: "value", mask: maskFunctions.currency.mask },
  {
    keys: ["responsible_type"],
    name: "responsible_type",
    options: responsibleTypeOptions,
  },
  {
    keys: ["security_type"],
    name: "security_type",
    options: securityTypeOptions,
  },
  { keys: ["warranty_type"], name: "warranty_type", options: warrantyOptions },
];

export const contractorDataDefaultForm: IMapFieldsProps[] = [
  {
    keys: ["condo_value"],
    name: "condo_value",
    mask: maskFunctions.currency.mask,
  },
  { keys: ["date_end"], name: "date_end", mask: correctDate2 },
  { keys: ["date_init"], name: "date_init", mask: correctDate2 },
  {
    keys: ["expiration_day"],
    name: "expiration_day",
    mask: maskFunctions.onlyNumber.mask,
  },
  { keys: ["id"], name: "id" },
  {
    keys: ["iptu_value"],
    name: "iptu_value",
    mask: maskFunctions.currency.mask,
  },
  { keys: ["is_managed"], name: "is_managed", options: isManagerOptions },
  { keys: ["managed_type"], name: "managed_type" },
  { keys: ["managed_value"], name: "managed_value" },
  { keys: ["observation"], name: "observation" },
  { keys: ["occupation"], name: "occupation", mask: correctDate2 },
  {
    keys: ["readjustment_index"],
    name: "readjustment_index",
    options: readjustmentIndicesOptions,
  },
  {
    keys: ["rent_value"],
    name: "rent_value",
    mask: maskFunctions.currency.mask,
  },
  { keys: ["rental_fee_type"], name: "rental_fee_type" },
  { keys: ["rental_fee_value"], name: "rental_fee_value" },
];

export const mapOptions = (props: IMapOptionsProps) => {
  const { valueKeys, labelKeys, item } = props;
  let value: any = copyOf(item);
  let label: any = copyOf(item);
  if (valueKeys && labelKeys) {
    valueKeys.forEach((key) => {
      value = value?.[key];
    });

    labelKeys.forEach((key) => {
      label = label?.[key];
    });
  }
  if (value && label) return { value, label, this: item };
  else return null;
};

export const mapDefaultForm = (props: IMapDefaultFormProps) => {
  const { data, fields } = props;
  const _data: any = {};

  fields.forEach((field) => {
    const { keys, name, valueKeys, labelKeys, options, mask, isJson } = field;
    let value: any = copyOf(data);

    keys.forEach((key) => {
      value = value?.[key];
    });

    if (isJson && value) value = JSON.parse(value);

    if (valueKeys && labelKeys) {
      if (Array.isArray(value)) {
        value = value.map((item: any) =>
          mapOptions({ valueKeys, labelKeys, item })
        );
      } else {
        value = mapOptions({ valueKeys, labelKeys, item: value });
      }
    }

    if (options) value = options.find((option) => option?.value === value);

    if (mask) value = mask(value, true);

    _data[name] = value;
  });

  return _data;
};
