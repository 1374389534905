import Button from "../../../Buttons/Button";
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { secondary } from "../../../../theme";
import { useApi } from "../../../../hooks/api";
import { Column } from "../../../../style";
import { useGlobal } from "../../../../hooks/global";
import { ModalLoading } from "../../../Loadings/ModalLoading";
import { accessTypesOptions } from "../../../../utils/options";
import { IGroupProps, userProps } from "../../../../interfaces";
import { ContainerButtons, ContainerForm, Form } from "./style";
import { GenericForm, IGenericFormRefProps } from "../../../GenericForm";

interface IFormReservationModalProps {
  onCancel: Function;
  onSave: Function;
  defaultData?: userProps | null;
}

export const FormReservation: React.FC<IFormReservationModalProps> = (
  props
) => {
  const { onSave, defaultData } = props;

  const { theme, notify } = useGlobal();
  const { api, consultsServices } = useApi();

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    setValue,
    trigger,
  } = useForm({
    defaultValues: defaultData?.id ? defaultData : ({} as any),
    mode: "all",
  });

  const _form = watch();

  const form_ref = useRef<IGenericFormRefProps>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const [loadingCreate, setLoadingCreate] = useState(false);

  const onSubmit = useCallback(async () => {
    setLoadingCreate(true);

    try {
      const form = form_ref.current?.getForm();

      if (defaultData?.id) {
        await api.put(`/accommodations/${defaultData?.id}`, form);
      } else {
        await api.post("/accommodations", form);
      }

      notify(
        `Acomodação ${defaultData?.id ? "atualizada" : "criada"} com sucesso!`,
        "success"
      );

      onSave();
    } catch (err: any) {
      const error = err.response ? err.response?.data : "SERVER ERROR";
      if (error.field)
        setError(error.field, { type: "manual", message: error.detail });
      else notify("ERRO INTERNO DO SISTEMA", "error");
    }

    setLoadingCreate(false);
  }, [form_ref, _form, api]);

  const handleStopPropagation = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e?.stopPropagation();
      handleSubmit(onSubmit)(e);
    },
    [handleSubmit, onSubmit, form_ref, _form, api]
  );

  const groups: IGroupProps[] = [
    {
      name: "",
      label: "",
      fields: [
        [
          {
            name: "id",
            label: "ID da Reserva",
            type: "input",
            getIsDisabled: () => !!defaultData?.id,
          },
          {
            name: "accommodation_stays_id",
            label: "ID da Acomodação",
            type: "select-fixed",
            getIsDisabled: () => !!defaultData?.id,
            options: [],
          },
        ],
        [
          {
            name: "check_in",
            label: "Entrada",
            type: "input",
            getIsDisabled: () => !!defaultData?.id,
          },
          {
            name: "check_out",
            label: "Saída",
            type: "input",
            getIsDisabled: () => !!defaultData?.id,
          },
        ],
      ],
    },
  ];

  useEffect(() => {
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++)
      inputs[i].setAttribute("autocomplete", "new-password");
  }, [_form.access_type]);

  return (
    <>
      <Column style={{ flex: 1, overflow: "auto", padding: 20 }}>
        <ContainerForm open>
          <Form onSubmit={handleStopPropagation}>
            <GenericForm
              ref={form_ref}
              groups={groups}
              _form={_form}
              control={control}
              trigger={trigger}
              errors={errors}
              setValue={setValue}
              register={register}
            />
            <button
              className="display-none"
              type="submit"
              ref={submitButtonRef}
            />
          </Form>
        </ContainerForm>
      </Column>

      <ContainerButtons theme={theme}>
        <Button
          onClick={() => submitButtonRef.current?.click()}
          background={secondary[theme]}
          color={"#FFF"}
          style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
          children={"Salvar"}
        />
      </ContainerButtons>

      <ModalLoading loading={loadingCreate} theme={theme} />
    </>
  );
};
