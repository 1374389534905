interface colorProps {
  light: string;
  dark: string;
}

export const primary: colorProps = {
  light: "#FFF",
  dark: "#242322",
};
export const secondary: colorProps = {
  light: "rgb(40 187 71)",
  dark: "rgb(40 187 71)",
  // light: "#61FF83",
  // dark: "#61FF83",
};

export const background: colorProps = {
  light: "#f8f8f8",
  dark: "#1B191B",
};

export const borderDisabled: colorProps = {
  light: "#e2e2e2",
  dark: "transparent",
};

export const activeItens: colorProps = {
  light: "rgb(40 187 71)",
  dark: "rgb(40 187 71)",
};

export const inactiveItens: colorProps = {
  light: "#3a3a3a",
  dark: "#8A8A8A",
};

export const activeSubItens: colorProps = {
  light: "rgb(40 187 71)",
  dark: "rgb(40 187 71)",
};

export const borderColors: colorProps = {
  light: "#CCCCCC",
  dark: "rgb(68 68 68)",
};

export const disabledItems: colorProps = {
  light: "#F2F2F2",
  dark: "#545454",
};

export const inactiveSubItens: colorProps = {
  light: "#3a3a3a",
  dark: "#707070",
};

export const hover: colorProps = {
  light: "#e3eff8",
  dark: "#e9f3fa",
};

export const separator: colorProps = {
  light: "#f3f3f3",
  dark: "#d7d7d7",
};

export const foreground: colorProps = {
  light: "#fff",
  dark: "#fff",
};

export const divider: colorProps = {
  light: "#dee2e6",
  dark: "#535353",
};

export const tabActiveColor: colorProps = {
  light: "rgb(42 42 42)",
  dark: "#C4C4C4",
};

export const tabInativeColor: colorProps = {
  light: "#C4C4C4",
  dark: "#707070",
};

export const shadowColor: colorProps = {
  light: "rgba(0,0,0,0.1)",
  dark: "#121111",
};

export const rankingBackgroundOne: colorProps = {
  light: "rgb(241, 242, 243)",
  dark: "#3a3a3a",
};

export const rankingBackgroundTwo: colorProps = primary;

export const tertiary = "#07C5A6";
export const four = "#394F8F";

export const fail = "#FF6B6B";
export const success = "#4CAF50";
export const info = "#FAAD14";

export const gray = "#DBDBDB";
export const white = "#FFF";
export const backScan = "#BDE5F3";
