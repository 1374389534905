import React, { useCallback, useEffect, useState } from "react";
import { useApi } from "../../hooks/api";
import { useGlobal } from "../../hooks/global";
import { ITable } from "../../components/TableList";
import { PageList } from "../../components/PageList";
import { getFilterFields } from "../../utils/filterFields";
import { setUpConsultation } from "../../services/generalServices";
import { IActions, IFilters } from "../../interfaces/index";
import { IPermissionsTypes } from "../../utils/permissions";
import { maskFunctions } from "../../services/maskServices";

export interface History {
    history_id: number;
    cpf: string;
    passport: null;
    uid: null;
    authorized: boolean;
    timestamp: number;
    is_sdk_user: boolean;
    created_at: number;
    from: string;
    from_id: string;
    to: string;
    to_id: string;
}

const initialFilters: IFilters[] = [getFilterFields("created_at_histories")];

const initialTable: ITable = {
    data: [],
    columns: [
        {
            attr: "cpf",
            name: "CPF",
            limitWidth: true,
            style: {},
            styleContent: { fontSize: 16, fontWeight: 600 },
        },
        {
            attr: "passport",
            name: "Passaporte",
            limitWidth: true,
            style: {},
            styleContent: {},
        },
        {
            attr: "from",
            name: "De",
            style: { textAlign: "center" },
            styleContent: { textAlign: "center" },
        },
        {
            attr: "to",
            name: "Para",
            style: { textAlign: "center" },
            styleContent: { textAlign: "center" },
        },
        {
            attr: "created_at",
            name: "Criado em",
            style: { textAlign: "center" },
            styleContent: { textAlign: "center" },
        },
    ],
};

export const HistoriesList: React.FC = () => {
    const { user, api } = useApi();
    const { theme, paginationLimit, notify } = useGlobal();

    const [atualPage, setAtualPage] = useState(1);
    const [filters, setFilters] = useState<any>({});
    const [numOfPersons, setNumOfPersons] = useState(0);
    const [updatedAt, setUpdatedAt] = useState(new Date());
    const [loadingPersons, setLoadingPersons] = useState(true);
    const [searchString, setSearchString] = useState<string>("");
    const [numberOfPages, setNumberOfPages] = useState<number>(1);
    const [table, setTable] = useState<ITable>(initialTable);

    const searchUsers = useCallback(
        async (page, _filters = null) => {
            setAtualPage(page);
            setLoadingPersons(true);
            try {
                const filters_obj = _filters
                    ? _filters
                    : { ...filters, search: searchString };

                if (!filters_obj.created_at) {
                    filters_obj.created_at = new Date();
                    filters_obj.created_at.setDate(filters_obj.created_at.getDate() - 1);
                    filters_obj.created_at = filters_obj.created_at.toISOString().split('T')[0];
                }

                if (filters_obj.created_at.includes('/')) filters_obj.created_at = filters_obj.created_at.split('/').reverse().join('-');

                filters_obj.created_at = new Date(filters_obj.created_at);

                filters_obj.created_at = filters_obj.created_at.toISOString().split('T')[0];

                let string = setUpConsultation(filters_obj);
                let url = `/histories?page=${page}&limit=${paginationLimit}&${string}`;

                const result = await api.get(url);
                const new_users = result.data as History[];
                setTable((atual) => {
                    const new_table = { ...atual };
                    new_table.data = [];
                    new_users.forEach((user) => {
                        new_table.data.push({
                            cpf: user.cpf ? maskFunctions.cpf.mask(user.cpf) : null,
                            passport: user.passport,
                            from: user.from,
                            to: user.to,
                            created_at: new Date(user.created_at).toLocaleString(),
                            this: user,
                        });
                    });
                    return { ...new_table };
                });

                setNumOfPersons(result.data.length);
                setNumberOfPages(1);
                setUpdatedAt(new Date());
            } catch (err) {
                console.log(err);
                notify("Erro na busca pelos acessos!", "error");
            }
            setLoadingPersons(false);
        },
        [user, filters, searchString, theme]
    );

    const actionsInitial: IActions[] = [];

    useEffect(() => {
        searchUsers(1);
    }, [theme]);

    return (
        <PageList
            updatedAt={updatedAt}
            loading={loadingPersons}
            setLoading={setLoadingPersons}
            numberOfItems={numOfPersons}
            actions={actionsInitial}
            textHeader="Acessos"
            textItems="acessos encontrados"
            text_loading={"Buscando Acessos"}
            text_empty={"Nenhum acesso encontrado"}
            theme={theme}
            initialFilters={initialFilters}
            onChangeFilters={setFilters}
            table={table}
            have_edit={user.user_type.permissions.includes(
                "HISTORIES" as IPermissionsTypes
            )}
            pages={numberOfPages}
            page={atualPage}
            search={searchUsers}
            onChangeSearch={setSearchString}
            noActions
            noPagination
        >
        </PageList>
    );
};
