import Button from "../../../Buttons/Button";
import React, {
  FormEvent,
  useCallback,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { secondary } from "../../../../theme";
import { useApi } from "../../../../hooks/api";
import { Column } from "../../../../style";
import { useGlobal } from "../../../../hooks/global";
import { ModalLoading } from "../../../Loadings/ModalLoading";
import { ContainerButtons, ContainerForm, Form } from "./style";
import { GenericForm, IGenericFormRefProps } from "../../../GenericForm";
import { IFieldProps, IGroupProps, IOptionsProps, userProps } from "../../../../interfaces";
import { PERMISSIONS, permissionsLabels } from "../../../../utils/permissions";

interface IFormUserTypeModalProps {
  onCancel: Function;
  onSave: Function;
  defaultData?: userProps | null;
  accommodations: IOptionsProps[];
}

export const FormUserType: React.FC<IFormUserTypeModalProps> = (props) => {
  const { onSave, defaultData, accommodations } = props;

  const { theme, notify } = useGlobal();
  const { api } = useApi();

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    setValue,
    trigger,
  } = useForm({
    defaultValues: defaultData?.id ? defaultData : ({} as any),
    mode: "all",
  });

  const _form = watch();

  const form_ref = useRef<IGenericFormRefProps>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const [loadingCreate, setLoadingCreate] = useState(false);

  const onSubmit = useCallback(async () => {
    setLoadingCreate(true);

    try {
      const form = form_ref.current?.getForm();

      form.permissions = Object?.keys(PERMISSIONS)?.reduce(
        (acc: any, curr: any) => {
          acc?.push(form[curr]?.map(({ id }: any) => id));
          delete form?.[curr];
          return acc?.flat();
        },
        []
      ) ?? [];

      form.permissions = form.permissions?.filter(Boolean)

      form.accommodations = form?.accommodations?.map(({ id }: any) => id) ?? [];

      if (defaultData?.id) {
        await api.put(`/user-types/${defaultData?.id}`, form);
      } else {
        await api.post("/user-types", form);
      }

      notify(
        `Tipo de Usuário ${defaultData?.id ? "atualizado" : "criado"
        } com sucesso!`,
        "success"
      );

      onSave();
    } catch (err: any) {
      const error = err.response ? err.response?.data : "SERVER ERROR";
      if (error.field) setError(error.field, { type: "manual", message: error.detail });
      else notify("ERRO INTERNO DO SISTEMA", "error");
    }

    setLoadingCreate(false);
  }, [form_ref, _form, api]);

  const handleStopPropagation = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e?.stopPropagation();
      handleSubmit(onSubmit)(e);
    },
    [handleSubmit, onSubmit, form_ref, _form, api]
  );

  const groups: IGroupProps[] = [
    {
      name: "",
      label: "",
      fields: [
        [
          {
            name: "name",
            label: "Nome",
            type: "input",
            required: true,
          },
        ],
        [
          {
            label: "Acomodações",
            name: "accommodations",
            type: "select-fixed-multi",
            icon: "🏠",
            options: accommodations
          }
        ],
        ...Object.keys(PERMISSIONS).map((key) => {
          const field = [] as IFieldProps[];
          field.push({
            name: key,
            label: permissionsLabels[key] ?? "",
            type: "select-fixed-multi",
            isClearable: true,
            options: Object.values({ [key]: (PERMISSIONS as any)[key] })
              .reduce((acc: any, curr: any) => {
                const keys = Object.keys(curr);
                const permissions = keys.map((value: any) => ({
                  value,
                  label: curr[value],
                }));
                return [...acc, permissions];
              }, [])
              .flat(),
          });
          return field;
        })
      ],
    },
  ];

  return (
    <>
      <Column style={{ flex: 1, overflow: "auto", padding: 20 }}>
        <ContainerForm open>
          <Form onSubmit={handleStopPropagation}>
            <GenericForm
              ref={form_ref}
              groups={groups}
              _form={_form}
              control={control}
              trigger={trigger}
              errors={errors}
              setValue={setValue}
              register={register}
            />
            <button
              className="display-none"
              type="submit"
              ref={submitButtonRef}
            />
          </Form>
        </ContainerForm>
      </Column>

      <ContainerButtons theme={theme}>
        <Button
          onClick={() => submitButtonRef.current?.click()}
          background={secondary[theme]}
          color={"#FFF"}
          style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
          children={"Salvar"}
        />
      </ContainerButtons>

      <ModalLoading loading={loadingCreate} theme={theme} />
    </>
  );
};
