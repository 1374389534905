import React, { useCallback, useEffect, useState } from "react";
import { useApi } from "../../hooks/api";
import { useGlobal } from "../../hooks/global";
import { ITable } from "../../components/TableList";
import { PageList } from "../../components/PageList";
import { IPermissionsTypes } from "../../utils/permissions";
import { setUpConsultation } from "../../services/generalServices";
import { ModalAccommodation } from "../../components/Modals/ModalAccommodation";
import {
  IAccomodationsProps,
  IActions,
  IFilters,
} from "../../interfaces/index";
import { getFilterFields } from "../../utils/filterFields";
import { ModalLoading } from "../../components/Loadings/ModalLoading";
import { MdHotel } from "react-icons/md";
import { secondary } from "../../theme";
import { getAreasGroups, getSmartlock } from "../../utils/columnsTables";

const initialFilters: IFilters[] = [getFilterFields("access_type")];

const initialTable: ITable = {
  data: [],
  columns: [
    {
      attr: "stays_id",
      name: "ID",
      style: { textAlign: "center" },
      styleContent: { fontSize: 16, fontWeight: 600, textAlign: "center" },
    },
    {
      attr: "name",
      name: "Nome",
      style: { textAlign: "center" },
      styleContent: { fontSize: 16, fontWeight: 600, textAlign: "center" },
    },
    {
      attr: "address",
      name: "Localização",
      style: {},
      styleContent: { fontSize: 14 },
    },
    {
      attr: "access_type",
      name: "Tipo de acesso",
      style: { textAlign: "center" },
      styleContent: { fontSize: 16, fontWeight: 600, textAlign: "center" },
    },
    {
      attr: "smartlock_alias",
      name: "Fechadura",
      style: { textAlign: "center" },
      styleContent: { fontSize: 14, textAlign: "center" },
    },
    {
      attr: "areas_groups",
      name: "Áreas de acesso",
      style: { textAlign: "center" },
      styleContent: { fontSize: 14, textAlign: "center" },
    },
  ],
};

export const propertyInExtension = (address: any) => {
  if (!address) return "-";
  return `${address?.street}, ${address?.region}, Nº ${address?.streetNumber}, ${address?.city} - ${address?.stateCode}`;
};

const accessTypeMapper: any = {
  normal: "Fechadura comum",
  on: "Fechadura eletrônica online",
  off: "Fechadura eletrônica offline",
};

export const AccommodationsList: React.FC = () => {
  const { user, api } = useApi();
  const { theme, paginationLimit, notify } = useGlobal();

  const [atualPage, setAtualPage] = useState(1);
  const [filters, setFilters] = useState<any>({});
  const [openModal, setOpenModal] = useState(false);
  const [numOfAccommodations, setNumOfAccommodations] = useState(0);
  const [updatedAt, setUpdatedAt] = useState(new Date());
  const [loadingAccommodations, setLoadingAccommodations] = useState(true);
  const [searchString, setSearchString] = useState<string>("");
  const [numberOfPages, setNumberOfPages] = useState<number>(1);
  const [accommodationSelected, setAccommodationSelected] =
    useState<IAccomodationsProps | null>(null);
  const [table, setTable] = useState<ITable>(initialTable);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const selectAccommodation = useCallback(
    (accomoddation: IAccomodationsProps) => {
      setAccommodationSelected({ ...accomoddation });
      setOpenModal(true);
    },
    []
  );

  const searchAccommodations = useCallback(
    async (page, _filters = null) => {
      setAtualPage(page);
      setLoadingAccommodations(true);
      try {
        const filters_obj = _filters
          ? _filters
          : { ...filters, search: searchString };

        let string = setUpConsultation(filters_obj);
        let url = `/accommodations?page=${page}&limit=${paginationLimit}&${string}`;

        const result = await api.get(url);
        const new_accommodations = result.data.data as IAccomodationsProps[];
        setTable((atual) => {
          const new_table = { ...atual };
          new_table.data = [];
          new_accommodations.forEach((accommodation) => {
            new_table.data.push({
              id: accommodation.id,
              stays_id: accommodation.id,
              address: propertyInExtension(accommodation.address),
              access_type: accessTypeMapper[accommodation.access_type],
              name: accommodation.name,
              smartlock_alias: accommodation.smartlock_alias
                ? getSmartlock({
                    theme,
                    smartlock_alias: accommodation.smartlock_alias,
                  })
                : "-",
              areas_groups: getAreasGroups({
                areas_groups: accommodation?.areas_groups_ids ?? [],
                theme,
              }),
              this: accommodation,
            });
          });
          return { ...new_table };
        });

        setNumOfAccommodations(result.data.count);
        setNumberOfPages(Math.ceil(result.data.count / paginationLimit));
        setUpdatedAt(new Date());
      } catch (err) {
        notify("Erro na busca pelas acomodações!", "error");
      }
      setLoadingAccommodations(false);
    },
    [user, filters, searchString]
  );

  const openModalNewAccommodation = useCallback(() => {
    setAccommodationSelected(null);
    setOpenModal(true);
  }, []);

  const handleAction = (action_id: string) => {
    if (action_id === "new_item") openModalNewAccommodation();
  };

  const actionsInitial: IActions[] = [];

  const onSaveAccommodation = useCallback(() => {
    setOpenModal(false);
    searchAccommodations(1);
  }, [user, filters, searchString]);

  const handleConfirm = useCallback(
    async (id: string) => {
      setLoadingDelete(true);
      try {
        await api.delete(`/accommodations/${id}`);
        notify("Acomodação excluída com sucesso!", "success");
        await searchAccommodations(1);
      } catch (error: any) {
        const default_message = "Erro ao excluir a acomodação!";
        const message = error.response?.data?.detail ?? default_message;
        notify(message, message === default_message ? "error" : "alert");
      }
      setLoadingDelete(false);
    },
    [searchAccommodations]
  );

  const alertDelete = useCallback(
    async (type: IAccomodationsProps): Promise<void> => {
      const result = window.confirm(
        "Tem certeza que deseja excluir a acomodação " + type.stays_id
      );

      if (result) handleConfirm(type.id as unknown as string);
    },
    [handleConfirm]
  );

  const listAction = [
    {
      title: "Abrir acomodação na Stays",
      onClick: (item: any) => {
        window.open(`https://mgli.stays.net/i/apartment/${item.id}`, "_blank");
      },
      icon: <MdHotel color={secondary[theme]} size={20} />,
      disabled: () => false,
    },
  ];

  useEffect(() => {
    searchAccommodations(1);
  }, []);

  return (
    <PageList
      updatedAt={updatedAt}
      loading={loadingAccommodations}
      setLoading={setLoadingAccommodations}
      numberOfItems={numOfAccommodations}
      actions={actionsInitial}
      onClickOption={handleAction}
      textHeader="Acomodações"
      textItems="acomodações encontradas"
      text_loading={"Buscando Acomodações"}
      text_empty={"Nenhuma acomodação encontrada"}
      theme={theme}
      initialFilters={initialFilters}
      onChangeFilters={setFilters}
      table={table}
      have_edit={user.user_type.permissions.includes(
        "ACCOMMODATIONS-UPDATE" as IPermissionsTypes
      )}
      edit_action={(accomoddation: IAccomodationsProps) =>
        selectAccommodation(accomoddation)
      }
      delete_action={(accomoddation: IAccomodationsProps) =>
        alertDelete(accomoddation)
      }
      pages={numberOfPages}
      page={atualPage}
      search={searchAccommodations}
      onChangeSearch={setSearchString}
      listActions={listAction}
    >
      {openModal && (
        <ModalAccommodation
          onCancel={() => setOpenModal(false)}
          onSave={onSaveAccommodation}
          openModal={openModal}
          backgroundVisible={false}
          defaultId={accommodationSelected?.id}
        />
      )}
      {loadingDelete && <ModalLoading loading={loadingDelete} theme={theme} />}
    </PageList>
  );
};
