import { BiDislike, BiHomeAlt, BiMedal } from "react-icons/bi";
import { Column, Line } from "../style";
import { DashboardCard } from "../DashboardCard";
import { useGlobal } from "../../../hooks/global";
import { DashboardChartDoughnut } from "../DashboardChartDoughnut";
import { inactiveItens, primary, secondary } from "../../../theme";
import { returnNumberWithDot } from "../utils";
import { Skeleton } from "@mui/material";

interface IReservationsCardProps {
    statisticsPerStatus: { [key: string]: number }
    statisticsPerOrigin: { [key: string]: number }
    reservationInfos: {
        max: number;
        min: number;
        avg: number;
    }
    loading: boolean
}

const colors = ["#FFC107", "#FF5722", "#4CAF50", "#2196F3", "#9C27B0", "#673AB7", "#3F51B5", "#00BCD4", "#009688", "#FF9800", "#795548", "#607D8B"];

const getArrayOfRandomColors = (length: number) => {
    const array = [];
    for (let i = 0; i < length; i++) {
        array.push(colors[i % colors.length]);
    }
    return array;
}

const CardWithIcon = ({ icon, subTitle, value, background }: any) => {
    const { theme } = useGlobal();

    return (
        <Line style={{ gap: 10 }}>
            <div>
                <div
                    style={{
                        background,
                        padding: 10,
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {icon}
                </div>
            </div>
            <Column style={{ color: inactiveItens[theme] }}>
                <div style={{ fontSize: 20, fontWeight: 600 }}>
                    {value}
                </div>
                <div style={{ fontSize: 14, marginTop: -5 }}>{subTitle}</div>
            </Column>
        </Line>
    );
};

const reservation_infos = [
    {
        subTitle: "Maior reserva",
        attribute: "max",
        color: "#52C41A",
        icon: <BiMedal color="#FFF" size={26} />,
    },
    {
        subTitle: "Menor reserva",
        attribute: "min",
        color: "#FF6B6B",
        icon: <BiDislike color="#FFF" size={26} />,
    },
    {
        subTitle: "Valor médio das reserva",
        attribute: "avg",
        color: "#FAAD14",
        icon: <BiHomeAlt color="#FFF" size={26} />,
    },
];

const status_mapper: any = {
    'BOOKED': {
        'title': 'Reservado',
        'color': '#4CAF50',
    },
    'CANCELED': {
        'title': 'Cancelado',
        'color': '#FF5722',
    },
    'RESERVED': {
        'title': 'Pré-reserva',
        'color': '#FFC107',
    },
}

const origin_mapper: any = {
    'EXTERNAL API': 'WE.HOUSY APP'
}

export const ReservationsCard: React.FC<IReservationsCardProps> = ({ reservationInfos, statisticsPerOrigin, statisticsPerStatus, loading }) => {
    const { theme } = useGlobal();


    if (loading) return (
        <Line style={{ gap: 30, flexWrap: "wrap" }}>
            <Skeleton
                variant="rectangular"
                animation="wave"
                style={{ minWidth: 250, borderRadius: 5, flex: 1, height: 317 }}
            />

            <Skeleton
                variant="rectangular"
                animation="wave"
                style={{ minWidth: 250, borderRadius: 5, flex: 1, height: 317 }}
            />

            <Skeleton
                variant="rectangular"
                animation="wave"
                style={{ minWidth: 250, borderRadius: 5, flex: 1, height: 317 }}
            />
        </Line>
    )

    return (
        <Line style={{ color: secondary[theme], gap: 30, flexWrap: "wrap" }}>
            <DashboardChartDoughnut
                theme={theme}
                title={'Reservas por Origem'}
                infos={Object.values(statisticsPerOrigin)}
                labels={Object.keys(statisticsPerOrigin).map((key) => origin_mapper?.[key] ?? key)}
                colors={getArrayOfRandomColors(Object.keys(statisticsPerOrigin).length)}
                minWidth={250}
            />
            <DashboardChartDoughnut
                theme={theme}
                title={'Reservas por Status'}
                infos={Object.values(statisticsPerStatus)}
                labels={Object.keys(statisticsPerStatus).map((key) => status_mapper?.[key]?.title ?? key)}
                colors={Object.keys(statisticsPerStatus).map((key) => status_mapper?.[key]?.color ?? key)}
                minWidth={250}
            />
            <DashboardCard
                minWidth={250}
                title="Valor das reservas"
                theme={theme}
            >
                <Column
                    style={{
                        background: primary[theme],
                        padding: 20,
                        gap: 20,
                        flex: 1,
                        borderBottomLeftRadius: 5,
                        borderBottomRightRadius: 5,
                        justifyContent: "space-between",
                    }}
                >
                    {reservation_infos.map(({ subTitle, attribute, icon, color }) => (
                        <CardWithIcon
                            key={subTitle}
                            icon={icon}
                            subTitle={subTitle}
                            value={'R$ ' + returnNumberWithDot(reservationInfos[attribute as 'max' | 'min' | 'avg'])}
                            background={color}
                        />
                    ))}
                </Column>
            </DashboardCard>
        </Line>
    )
}