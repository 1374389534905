import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import React, { useCallback } from "react";
import { useApi } from "../../hooks/api";
import { BiUserCircle } from "react-icons/bi";
import { useGlobal } from "../../hooks/global";
import { useNavigate } from "react-router-dom";
import { FaRegBuilding } from "react-icons/fa";
import { RiLoginBoxLine } from "react-icons/ri";
import { ProfileButton } from "../Buttons/Profile";
import { LogoDark } from "../SvgComponents/Logo/Dark";
import { LogoLight } from "../SvgComponents/Logo/Light";
import { background } from "../../theme";
import { FiMenu } from "react-icons/fi";
import {
  Button,
  Container,
  ContainerLogo,
  Content,
  Logo,
  SwitchContainer,
} from "./style";
import { AiFillCamera } from "react-icons/ai";

interface HeaderProps {
  chooseFranchise?: boolean;
}

const options: any = {
  root: [{ name: "Sair", icon: <RiLoginBoxLine size={16} /> }],
  responsible: [
    { name: "Meu Perfil", icon: <BiUserCircle size={16} /> },
    { name: "Dados da Empresa", icon: <FaRegBuilding size={16} /> },
    { name: "Alterar foto", icon: <AiFillCamera size={16} /> },
    { name: "Sair", icon: <RiLoginBoxLine size={16} /> },
  ],
  manager: [
    { name: "Meu Perfil", icon: <BiUserCircle size={16} /> },
    { name: "Alterar foto", icon: <AiFillCamera size={16} /> },
    { name: "Sair", icon: <RiLoginBoxLine size={16} /> },
  ],
  analyst: [
    { name: "Meu Perfil", icon: <BiUserCircle size={16} /> },
    { name: "Alterar foto", icon: <AiFillCamera size={16} /> },
    { name: "Sair", icon: <RiLoginBoxLine size={16} /> },
  ],
  broker: [
    { name: "Meu Perfil", icon: <BiUserCircle size={16} /> },
    { name: "Alterar foto", icon: <AiFillCamera size={16} /> },
    { name: "Sair", icon: <RiLoginBoxLine size={16} /> },
  ],
};

export const Header: React.FC<HeaderProps> = (props) => {
  const { chooseFranchise } = props;

  const navigate = useNavigate();

  const { signOut } = useApi();
  const {
    setMenuOpen,
    menuOpen,
    setTheme,
    theme,
    menuItemSelected,
    setSubMenuOpen,
    subMenuOpen,
  } = useGlobal();

  const clickButtonMenu = useCallback(() => {
    if (subMenuOpen) setSubMenuOpen(false);
    else setMenuOpen(!menuOpen);
  }, [menuOpen, menuItemSelected, subMenuOpen]);

  const toggleFullScreen = () => {
    if (document.fullscreenEnabled) {
      if (!document.fullscreen) document.documentElement.requestFullscreen();
      else document.exitFullscreen();
    }
  };

  const clickInOption = useCallback((id, option) => {
    if (option === "Sair") signOut();
    else if (option === "Meu Perfil") navigate("/perfil");
  }, []);

  return (
    <Container id="header-content" theme={theme}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          minWidth: "100vw",
        }}
      >
        <Content>
          <ContainerLogo chooseFranchise={!!chooseFranchise}>
            <Logo chooseFranchise={!!chooseFranchise}>
              {theme === "dark" && <LogoDark />}
              {theme === "light" && <LogoLight />}
            </Logo>
            {!chooseFranchise && (
              <FiMenu
                onClick={clickButtonMenu}
                style={{ cursor: "pointer" }}
                color={background[theme === "dark" ? "light" : "dark"]}
                size={30}
              />
            )}
          </ContainerLogo>
          <div style={{ display: "flex", alignItems: "center" }}>
            <SwitchContainer>
              <Switch
                id="tooltip_switch"
                className="custom-switch custom-switch-primary custom-switch-small"
                checked={theme === "dark"}
                onChange={(value) => setTheme(value ? "dark" : "light")}
              />
            </SwitchContainer>

            <Button onClick={toggleFullScreen} isCollapse theme={theme}>
              <i
                className="i-Full-Screen"
                color="#FFF"
                data-fullscreen
                style={{ color: "#6c757d", fontSize: 22 }}
              />
            </Button>

            <ProfileButton
              id="profile-button"
              photo={null}
              onClick={clickInOption}
              options={options["root"]}
              theme={theme}
            />
          </div>
        </Content>
      </div>
    </Container>
  );
};
