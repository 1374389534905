import React, { useCallback, useEffect, useState } from "react";
import { Container } from "./style";
import { FormAccommodation } from "./form/index";
import { useApi } from "../../../hooks/api";
import { ModalHeader } from "../../ModalHeader";
import { useGlobal } from "../../../hooks/global";
import { ModalGeneric } from "../../ModalGeneric";
import { ContentOrLoading } from "../../Loadings/ContentOrLoading";
import { accessTypesOptions, get_corrrect_names } from "../../../utils/options";

interface IModalAccommodationProps {
  onCancel: Function;
  onSave: Function;
  defaultId?: string;
  openModal: boolean;
  backgroundVisible?: boolean;
}

const texts: any = {
  title: {
    new: "Cadastro de Acomodação",
    edit: "Informações da Acomodação",
  },
  subTitle: {
    new: "Informe os dados da acomodação",
    edit: "Segue abaixo as informações da acomodação",
  },
};

export const ModalAccommodation: React.FC<IModalAccommodationProps> = (
  props
) => {
  const { onCancel, onSave, openModal, backgroundVisible, defaultId } = props;

  const { api } = useApi();
  const { theme, notify } = useGlobal();

  const [loading, setLoading] = useState(!!defaultId);
  const [defaultData, setDefaultData] = useState<any>(null);
  const [areasGroupsOptions, setAreasGroupsOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [smartlocksOptions, setSmartlocksOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const searchAreasGroups = useCallback(async () => {
    const { data } = await api.get("/area-groups");
    const _data = data.map((item: { id: string; name: string }) => ({
      label: item.id + " - " + item.name,
      value: String(item.id),
    }));

    setAreasGroupsOptions(_data);

    return _data;
  }, []);

  const searchSmartlocks = useCallback(async () => {
    const { data } = await api.get("/smartlocks/all", {
      params: {
        stays_accommodation_id: defaultId,
      },
    });

    const _data = data.map((item: { alias: string; name: string }) => ({
      label: item.alias + " - " + item.name,
      value: item.alias,
    }));

    setSmartlocksOptions(_data);

    return _data;
  }, [defaultId]);

  const searchAccommodation = useCallback(async () => {
    if (defaultId) {
      setLoading(true);
      try {
        const [areasGroups, smartlocks] = await Promise.all([
          searchAreasGroups(),
          searchSmartlocks(),
        ]);
        const { data } = await api.get(`/accommodations/${defaultId}`);
        const _data = {} as any;

        _data.id = data.id;
        _data.stays_id = data.stays_id;
        _data.access_type = {
          value: data.access_type,
          label: get_corrrect_names(accessTypesOptions)?.[data?.access_type],
        };
        _data.password = data.password;
        _data.smartlock_alias = smartlocks.find(
          (item: any) => String(item.value) === String(data.smartlock_alias)
        );
        _data.areas_groups_ids = areasGroups?.filter((area: any) =>
          data?.areas_groups_ids?.includes(String(area.value))
        );

        setDefaultData({ ..._data });
      } catch (err) {
        console.log(err);
        notify("Erro ao buscar a acomodação", "error");
        onCancel();
      } finally {
        setLoading(false);
      }
    }
  }, [defaultId]);

  useEffect(() => {
    searchAccommodation();
  }, []);

  return (
    <ModalGeneric
      backgroundVisible={backgroundVisible}
      open={openModal}
      onClose={() => { }}
    >
      <Container theme={theme}>
        <ModalHeader
          title={texts.title[defaultId ? "edit" : "new"]}
          subTitle={texts.subTitle[defaultId ? "edit" : "new"]}
          onCancel={() => onCancel()}
          theme={theme}
        />
        <div style={{ marginBottom: 20 }} />
        <ContentOrLoading type="wave" loading={loading} theme={theme}>
          {!loading && (
            <FormAccommodation
              onSave={onSave}
              onCancel={onCancel}
              defaultData={defaultData}
              areasGroupsOptions={areasGroupsOptions}
              smartlocksOptions={smartlocksOptions}
            />
          )}
        </ContentOrLoading>
      </Container>
    </ModalGeneric>
  );
};
