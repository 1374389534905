import React, { useCallback, useEffect, useState } from "react";
import { Container } from "./style";
import { FormReservation } from "./form/index";
import { useApi } from "../../../hooks/api";
import { ModalHeader } from "../../ModalHeader";
import { useGlobal } from "../../../hooks/global";
import { ModalGeneric } from "../../ModalGeneric";
import { ContentOrLoading } from "../../Loadings/ContentOrLoading";
import { accessTypesOptions, get_corrrect_names } from "../../../utils/options";
import { correctDateDashboard } from "../../../services/generalServices";

interface IModalReservationsProps {
  onCancel: Function;
  onSave: Function;
  defaultId?: string;
  openModal: boolean;
  backgroundVisible?: boolean;
}

const texts: any = {
  title: {
    new: "Cadastro de Reserva",
    edit: "Informações da Reserva",
  },
  subTitle: {
    new: "Informe os dados da reserva",
    edit: "Segue abaixo as informações da reserva",
  },
};

export const ModalReservations: React.FC<IModalReservationsProps> = (props) => {
  const { onCancel, onSave, openModal, backgroundVisible, defaultId } = props;

  const { api } = useApi();
  const { theme, notify } = useGlobal();

  const [loading, setLoading] = useState(!!defaultId);
  const [defaultData, setDefaultData] = useState<any>(null);

  const searchReservation = useCallback(async () => {
    if (defaultId) {
      setLoading(true);
      try {
        const { data } = await api.get(`/stays-reservations/${defaultId}`);
        const _data = {} as any;
        const checkInDate = String(data.check_in_date).split("T")[0];
        const checkInTime = String(data.check_in_time);
        const checkIn = correctDateDashboard(
          new Date(checkInDate + " " + checkInTime)
        );

        const checkOutDate = String(data.check_out_date).split("T")[0];
        const checkOutTime = String(data.check_out_time);
        const checkOut = correctDateDashboard(
          new Date(checkOutDate + " " + checkOutTime)
        );
        _data.id = data.id;
        _data.accommodation_stays_id = data.accommodation_stays_id;
        _data.check_in = checkIn;
        _data.check_out = checkOut;
        _data.password = data.password;
        setDefaultData({ ..._data });
      } catch (err) {
        notify("Erro ao buscar a reserva", "error");
        onCancel();
      } finally {
        setLoading(false);
      }
    }
  }, [defaultId]);

  useEffect(() => {
    searchReservation();
  }, []);

  return (
    <ModalGeneric
      backgroundVisible={backgroundVisible}
      open={openModal}
      onClose={() => {}}
    >
      <Container theme={theme}>
        <ModalHeader
          title={texts.title[defaultId ? "edit" : "new"]}
          subTitle={texts.subTitle[defaultId ? "edit" : "new"]}
          onCancel={() => onCancel()}
          theme={theme}
        />
        <div style={{ marginBottom: 20 }} />
        <ContentOrLoading type="wave" loading={loading} theme={theme}>
          {!loading && (
            <FormReservation
              onSave={onSave}
              onCancel={onCancel}
              defaultData={defaultData}
            />
          )}
        </ContentOrLoading>
      </Container>
    </ModalGeneric>
  );
};
