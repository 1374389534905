import './style.css';
import Input from '../../../Inputs/Input';
import Button from "../../../Buttons/Button";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { secondary } from "../../../../theme";
import { useApi } from "../../../../hooks/api";
import { Editor } from '@tinymce/tinymce-react';
import { EmailSimulator } from '../EmailSimulator';
import { useGlobal } from "../../../../hooks/global";
import { IOptionsProps } from "../../../../interfaces";
import { Column, Label, Line } from "../../../../style";
import { ModalLoading } from "../../../Loadings/ModalLoading";
import { ContainerButtons, ContainerForm, Form, VariableButton } from "./style";
import { FixRequiredSelect } from "../../../Inputs/BaseSelects/FixRequiredSelect";
import { SelectCreateMulti } from '../../../Inputs/BaseSelects/SelectCreateMulti';

interface IFormSendEmailMessageModalProps {
  onCancel: Function;
  onSave: Function;
  options: IOptionsProps[];
  emails: string[];
  variables: any;
}

const ICONS_MAPPER: any = {
  reservation: '🏨',
  accommodation: '🏠',
  'check-in': '🔑',
  guest: '🧑',
  general: '📅'
}

const VARIABLES = [
  { label: 'Código da Reserva', value: 'reservaCodigo', type: 'reservation' },
  { label: 'Data de Entrada', value: 'reservaDataEntrada', type: 'reservation' },
  { label: 'Data de Saída', value: 'reservaDataSaida', type: 'reservation' },
  { label: 'Status de Pagamento', value: 'reservaStatusPagamento', type: 'reservation' },
  { label: 'Status de Check-in', value: 'reservaStatusCheckin', type: 'reservation' },
  { label: 'Origem da Reserva', value: 'reservaOrigem', type: 'reservation' },
  { label: 'Data de Criação da Reserva', value: 'reservaDataCriacao', type: 'reservation' },
  { label: 'Data de Criação do Check-in', value: 'checkinDataCriacao', type: 'check-in' },
  { label: 'Senha da Acomodação', value: 'checkinSenhaAcomodacao', type: 'check-in' },
  { label: 'Senha da Área de Acesso', value: 'checkinSenhaAreaAcesso', type: 'check-in' },
  { label: 'Código da Acomodação', value: 'acomodacaoCodigo', type: 'accommodation' },
  { label: 'Nome da Acomodação', value: 'acomodacaoNome', type: 'accommodation' },
  { label: 'Localização da Acomodação', value: 'acomodacaoLocalizacao', type: 'accommodation' },
  { label: 'Tipo de Acesso da Acomodação', value: 'acomodacaoTipoAcesso', type: 'accommodation' },
  { label: 'Fechadura da Acomodação', value: 'acomodacaoFechadura', type: 'accommodation' },
  { label: 'Áreas de Acesso da Acomodação', value: 'acomodacaoAreasAcesso', type: 'accommodation' },
  { label: 'Primeiro nome do hóspede titular', value: 'hospedePrimeiroNomeTitular', type: 'guest' },
  { label: 'Nome do hóspede titular', value: 'hospedeNomeTitular', type: 'guest' },
  { label: 'E-mail do hóspede titular', value: 'hospedeEmailTitular', type: 'guest' },
  { label: 'CPF do hóspede titular', value: 'hospedeCPFTitular', type: 'guest' },
  { label: 'Passaporte do hóspede titular', value: 'hospedePassaporteTitular', type: 'guest' },
  { label: 'Telefone do hóspede titular', value: 'hospedeTelefoneTitular', type: 'guest' },
  // { label: 'Links dos documentos do hóspede titular', value: 'hospedeLinksDocsTitular', type: 'guest' },
  { label: 'Todos os dados dos hóspedes', value: 'hospedeTodos', type: 'guest' },
  { label: 'Data de hoje', value: 'hoje', type: 'general' },
  { label: 'Dia atual', value: 'diaAtual', type: 'general' },
  { label: 'Dia atual por extenso', value: 'diaAtualExtenso', type: 'general' },
  { label: 'Mês atual', value: 'mesAtual', type: 'general' },
  { label: 'Mês atual por extenso', value: 'mesAtualExtenso', type: 'general' },
  { label: 'Ano atual', value: 'anoAtual', type: 'general' },
  { label: 'Ano atual por extenso', value: 'anoAtualExtenso', type: 'general' },
]

export const FormSendEmailMessage: React.FC<IFormSendEmailMessageModalProps> = (props) => {
  const { onSave, options, variables } = props;

  const { theme, notify, notifyBottom } = useGlobal();
  const { api } = useApi();
  const [visualizationMode, setVisualizationMode] = useState('preview');
  const [message, setMessage] = useState("");
  const [selectedMessageModel, setSelectedMessageModel] = useState<any>(options?.[0]?.value);
  const [emails, setEmails] = useState([props.emails?.[0]]);
  const [emailsOptions, setEmailsOptions] = useState<any[]>(() => {
    const emails = props.emails ?? [];
    const uniquesEmails = emails.filter((item, index) => emails.indexOf(item) === index);
    return uniquesEmails.map((item) => ({ value: item, label: item }))
  });
  const [customVariables, setCustomVariables] = useState<any[]>(VARIABLES.map((v) => ({ ...v, text: variables[v.value] ?? '' })));
  const [subject, setSubject] = useState(options?.[0]?.this?.subject);

  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const [loadingCreate, setLoadingCreate] = useState(false);

  const onSubmit = useCallback(async () => {
    setLoadingCreate(true);

    try {
      await api.post('/send-email-personalized', { content: message, emails, subject })
      notify('Email enviado com sucesso!', 'success');

      // onSave();
    } catch (err: any) {
      const message = err?.response?.data?.detail ?? "Erro ao enviar email";
      notify(message, "error");
    }

    setLoadingCreate(false);
  }, [message, emails, subject, api]);

  const handleStopPropagation = useCallback(
    (e: any) => {
      e?.preventDefault();
      e?.stopPropagation();
      const confirm = window.confirm("Deseja realmente enviar o email?");
      if (confirm) onSubmit();
    },
    [onSubmit, api]
  );

  const copyText = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        notifyBottom("Variável copiada para área de tranferência!", "success");
      })
      .catch((err) => {
        notifyBottom("Erro ao copiar a variável para a área de tranferência!", "error");
      });
  };

  const handleEnableEdition = useCallback(() => {
    const result = window.confirm("Deseja realmente habilitar a edição do texto?");
    if (result) setVisualizationMode('text');
  }, [])

  useEffect(() => {
    const findedModel = options.find((item) => item.value === selectedMessageModel);
    if (findedModel) {
      setMessage(findedModel?.this?.text)
    }
  }, [selectedMessageModel])

  useEffect(() => {
    let newMessage = `${message}`;
    let newSubject = `${subject}`;
    for (const variable of customVariables) {
      if (newMessage.includes(`{{${variable.value}}}`)) {
        newMessage = newMessage.replaceAll(`{{${variable.value}}}`, variable.text.replaceAll('\n', '<br/>'));
      }
      if (newSubject.includes(`{{${variable.value}}}`)) {
        newSubject = newSubject.replaceAll(`{{${variable.value}}}`, variable.text);
      }
    }
    if (newMessage !== message) setMessage(newMessage);
    if (newSubject !== subject) setSubject(newSubject);
  }, [message, subject, customVariables])

  return (
    <>
      <Column style={{ overflow: "auto" }}>
        <Line style={{ flex: 1, gap: 16, padding: 16, flexWrap: 'wrap' }}>
          <ContainerForm open style={{ flex: 4, paddingBottom: 0 }}>
            <Form onSubmit={handleStopPropagation}>
              <Column style={{ gap: 16, flex: 1 }}>
                <Column style={{ gap: 16 }}>
                  <Line style={{ gap: 16, flexWrap: "wrap" }}>
                    <Column style={{ flex: 1 }}>
                      <Label>Modelo*</Label>
                      <FixRequiredSelect
                        placeholder=""
                        options={options}
                        value={options.find((item) => item.value === selectedMessageModel)}
                        onChange={(value: any) => {
                          setSelectedMessageModel(value?.value)
                          setSubject(value?.this?.subject)
                        }}
                      />
                    </Column>
                    <Column style={{ flex: 3 }}>
                      <Label>Assunto*</Label>
                      <Input
                        placeholder=""
                        value={subject}
                        onChange={(value: any) => setSubject(value)}
                        required
                        noUpperCase
                      />
                    </Column>
                  </Line>
                  <Line style={{ gap: 16, flexWrap: "wrap" }}>
                    <Column style={{ flex: 10 }}>
                      <Label>Email*</Label>
                      <SelectCreateMulti
                        defaultValue={emailsOptions.filter((item) => emails.includes(item?.value))}
                        onChange={(value: any) => setEmails(value?.map((item: any) => item?.value))}
                        searchOptions={async () => {
                          return new Promise((resolve, reject) => {
                            return resolve({ formatted: emailsOptions });
                          });
                        }}
                        onCreateOption={(data: any) => {
                          const newEmails = [...emails, data];
                          const uniquesEmails = newEmails.filter((item, index) => newEmails.indexOf(item) === index);
                          setEmailsOptions(uniquesEmails.map((item) => ({ value: item, label: item })));
                          setEmails(uniquesEmails);
                        }}
                      />
                    </Column>
                    {visualizationMode === 'preview' && (
                      <Column style={{ alignItems: "flex-end", justifyContent: "flex-end", flex: 1 }}>
                        <Button
                          onClick={handleEnableEdition}
                          background={secondary[theme]}
                          color={"#FFF"}
                          style={{ padding: 8, maxWidth: 150, fontSize: 14, whiteSpace: "nowrap" }}
                        >
                          Habilitar edição
                        </Button>
                      </Column>
                    )}
                  </Line>
                </Column>
                <Line>
                  <Line style={{ flexWrap: "wrap", gap: 16, flex: 1 }}>
                    {visualizationMode === 'text' && (
                      <Column style={{ flex: 1, minHeight: 500, minWidth: 350 }}>
                        <Editor
                          apiKey="bypgaef6jor1xklepivwa1iftz9u5o1bhm004dd1dvo7tkx2"
                          value={message}
                          init={{
                            height: '100%',
                            width: '100%',
                            language: 'pt_BR',
                            menubar: false,
                            plugins: 'advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount',
                            toolbar: 'fontsize fontfamily | bold italic underline strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist table | link image fullscreen ',
                            resize: false,
                          }}
                          onEditorChange={(value: string) => setMessage(value)}
                        />
                      </Column>
                    )}
                    <Column style={{ flex: 1, minWidth: 350 }}>
                      <EmailSimulator text={message} />
                    </Column>
                  </Line>
                </Line>
              </Column>
              <button
                className="display-none"
                type="submit"
                ref={submitButtonRef}
              />
            </Form>
          </ContainerForm>
          <div style={{ display: "flex", flexDirection: "column", gap: 10, minWidth: 300, flex: 1 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ fontWeight: "bold" }}>Variáveis</div>
              <div style={{ fontSize: 14 }}>
                Clique em uma variável para copiar a chave correspondente.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 10, padding: 10, marginLeft: -10 }}>
              {customVariables.map((v) => (
                <VariableButton onClick={() => copyText(v.text)} key={v.value} theme={theme}>
                  {ICONS_MAPPER[v.type]} {v.label}
                </VariableButton>
              ))}
            </div>
          </div>
        </Line>
      </Column>
      <ContainerButtons theme={theme}>
        <Button
          onClick={() => submitButtonRef.current?.click()}
          background={secondary[theme]}
          color={"#FFF"}
          style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
          children={"Enviar Email"}
        />
      </ContainerButtons>

      <ModalLoading loading={loadingCreate} theme={theme} />
    </>
  );
};
