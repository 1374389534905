import React, { useCallback, useEffect, useState } from "react";
import { useApi } from "../../hooks/api";
import { useGlobal } from "../../hooks/global";
import { ITable } from "../../components/TableList";
import { IoAddCircleOutline } from "react-icons/io5";
import { PageList } from "../../components/PageList";
import { IPermissionsTypes } from "../../utils/permissions";
import { ModalUser } from "../../components/Modals/ModalUser";
import { setUpConsultation } from "../../services/generalServices";
import { IActions, IFilters, userProps } from "../../interfaces/index";
import { maskFunctions } from "../../services/maskServices";

const initialFilters: IFilters[] = [];

const initialTable: ITable = {
  data: [],
  columns: [
    {
      attr: "name",
      name: "Nome",
      limitWidth: true,
      style: {},
      styleContent: { fontSize: 14, fontWeight: "bold" },
    },
    {
      attr: "email",
      name: "Email",
      limitWidth: true,
      style: {},
      styleContent: { fontSize: 14 },
    },
    {
      attr: "cpf",
      name: "CPF",
      limitWidth: true,
      style: {},
      styleContent: { fontSize: 14 },
    },
    {
      attr: "passport",
      name: "Passaporte",
      limitWidth: true,
      style: {},
      styleContent: { fontSize: 14 },
    },
    {
      attr: "phone",
      name: "Telefone",
      limitWidth: true,
      style: {},
      styleContent: { fontSize: 14 },
    },
    {
      attr: "address",
      name: "Endereço",
      limitWidth: true,
      style: {},
      styleContent: { fontSize: 14 },
    }
  ],
};

export const propertyInExtension = (address: any) => {
  const attrs: any = {
    'street': 'Rua',
    'neighborhood': 'Bairro',
    'number': 'Número',
    'city': 'Cidade',
    'state': 'Estado'
  }

  for (const attr of Object.keys(attrs)) {
    if (!address[attr as any]) address[attr] = `[${attrs[attr]}]`
  }

  return `${address?.street}, ${address?.neighborhood}, Nº ${address?.number}, ${address?.city} - ${address?.state}`;
};

export const ClientsList: React.FC = () => {
  const { user, api } = useApi();
  const { theme, paginationLimit, notify } = useGlobal();

  const [atualPage, setAtualPage] = useState(1);
  const [filters, setFilters] = useState<any>({});
  const [openModal, setOpenModal] = useState(false);
  const [numOfPersons, setNumOfPersons] = useState(0);
  const [updatedAt, setUpdatedAt] = useState(new Date());
  const [loadingPersons, setLoadingPersons] = useState(true);
  const [searchString, setSearchString] = useState<string>("");
  const [numberOfPages, setNumberOfPages] = useState<number>(1);
  const [clientSelected, setClientSelected] = useState<userProps | null>(null);
  const [table, setTable] = useState<ITable>(initialTable);

  const changeStatusClient = useCallback(
    async (userId: string, old_status: boolean) => {
      const new_active = !old_status;

      setTable((atual) => {
        const new_table = { ...atual };
        const pos = new_table.data.findIndex((d) => d?.this.id === userId);
        if (pos !== -1) new_table.data[pos].this.active = new_active;
        return { ...new_table };
      });

      try {
        await api.put(`/users/${userId}`, { active: new_active });
        notify("Status do cliente atualizado com sucesso.", "success");
      } catch (err) {
        notify("Erro ao alterar status do cliente.", "error");
        setTable((atual) => {
          const new_table = { ...atual };
          const pos = new_table.data.findIndex((d) => d?.this.id === userId);
          if (pos !== -1) new_table.data[pos].this.active = old_status;
          return { ...new_table };
        });
      }
    },
    []
  );

  const searchClients = useCallback(
    async (page, _filters = null) => {
      setAtualPage(page);
      setLoadingPersons(true);
      try {
        const filters_obj = _filters
          ? _filters
          : { ...filters, search: searchString };

        filters_obj.only_client_users = true;

        let string = setUpConsultation(filters_obj);
        let url = `/users?page=${page}&limit=${paginationLimit}&${string}`;

        const result = await api.get(url);
        const new_users = result.data.data as userProps[];
        setTable((atual) => {
          const new_table = { ...atual };
          new_table.data = [];
          new_users.forEach((user) => {
            new_table.data.push({
              id: user.id,
              name: user?.firstName + " " + user?.lastName,
              cpf: user?.cpf ? maskFunctions.cpf.mask(user?.cpf) : undefined,
              passport: user?.passport ? user?.passport : undefined,
              phone: user?.phone ? maskFunctions.phone.mask(user?.phone) : undefined,
              address: propertyInExtension(user),
              surname: user?.surname,
              email: user.email,
              this: user,
            });
          });
          return { ...new_table };
        });

        setNumOfPersons(result.data.count);
        setNumberOfPages(Math.ceil(result.data.count / paginationLimit));
        setUpdatedAt(new Date());
      } catch (err) {
        notify("Erro na busca pelos clientes!", "error");
      }
      setLoadingPersons(false);
    },
    [user, filters, searchString, theme]
  );

  const openModalNewClient = useCallback(() => {
    setClientSelected(null);
    setOpenModal(true);
  }, []);

  const handleAction = (action_id: string) => {
    if (action_id === "new_item") openModalNewClient();
  };

  const onSaveClient = useCallback(() => {
    setOpenModal(false);
    searchClients(1);
  }, [user, filters, searchString]);

  useEffect(() => {
    searchClients(1);
  }, [theme]);

  return (
    <PageList
      updatedAt={updatedAt}
      loading={loadingPersons}
      setLoading={setLoadingPersons}
      numberOfItems={numOfPersons}
      onClickOption={handleAction}
      textHeader="Clientes"
      textItems="clientes encontrados"
      text_loading={"Buscando Clientes"}
      text_empty={"Nenhum cliente encontrado"}
      theme={theme}
      initialFilters={initialFilters}
      onChangeFilters={setFilters}
      table={table}
      pages={numberOfPages}
      page={atualPage}
      search={searchClients}
      onChangeSearch={setSearchString}
      have_status={user.user_type.permissions.includes(
        "USERS-UPDATE" as IPermissionsTypes
      )}
      noActions
      status_action={changeStatusClient}
    >
      {openModal && (
        <ModalUser
          onCancel={() => setOpenModal(false)}
          onSave={onSaveClient}
          openModal={openModal}
          backgroundVisible={false}
          defaultId={clientSelected?.id}
        />
      )}
    </PageList>
  );
};
