import React, { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { DashboardPage } from "../../components/DashboardPage";
import { useApi } from "../../hooks/api";
import { useGlobal } from "../../hooks/global";
import { Column } from "../../style";
import { ContainerButtons, ContainerForm, Form } from "./style";
import { GenericForm, IGenericFormRefProps } from "../../components/GenericForm";
import { useForm } from "react-hook-form";
import { IFieldProps, IGroupProps, MaskTypes } from "../../interfaces";
import { secondary } from "../../theme";
import Button from "../../components/Buttons/Button";
import { maskFunctions } from "../../services/maskServices";
import { ContentOrLoading } from "../../components/Loadings/ContentOrLoading";
import { ModalLoading } from "../../components/Loadings/ModalLoading";

const CONSTANTS_MAPPER: {
  [key: string]: {
    label: string,
    description: string,
    type: MaskTypes
  }
} = {
  CASHBACK_PERCENT: {
    label: "Porcentagem de cashback (%)",
    description: "Porcentagem de cashback que o usuário recebe ao realizar uma reserva",
    type: 'porcen'
  },
  DAYS_TO_ABLE_CHECK_IN: {
    label: "Dias para habilitar check-in",
    description: "Dias para habilitar o check-in antes da data de entrada",
    type: 'onlyNumber'
  },
  DAYS_TO_ENTER_POINTS: {
    label: "Dias para entrar pontos",
    description: "Dias para entrar pontos após a data de saída",
    type: 'onlyNumber'
  },
  DAYS_TO_EXPIRES_POINTS: {
    label: "Dias para expirar pontos",
    description: "Dias para expirar os pontos do usuário após a data de saída",
    type: 'onlyNumber'
  },
  DAYS_TO_NOTIFY_EXPIRES_POINTS: {
    label: "Dias para notificar expiração de pontos",
    description: "Dias para notificar a expiração dos pontos do usuário antes da data de expiração",
    type: 'onlyNumber'
  },
  EXCHANGE_RATE: {
    label: "Taxa de câmbio",
    description: "Taxa de câmbio para conversão de pontos em dinheiro, simboliza quantos pontos valem 1 real, ex: 1 real = 10 pontos, então a taxa de câmbio é 10",
    type: 'onlyNumber'
  },
  HOURS_TO_EXPIRES_RESERVATION: {
    label: "Horas para expirar reserva",
    description: "Horas para expirar a reserva após a criação, caso a reserva não seja confirmada",
    type: 'onlyNumber'
  },
  MAX_PENDING_RESERVATIONS: {
    label: "Máximo de reservas pendentes",
    description: "Máximo de reservas pendentes que um usuário pode ter",
    type: 'onlyNumber'
  },
  MAX_WALLET_USED_IN_RESERVATION: {
    label: "Percentual máximo de uma reserva que pode ser pago com a carteira (%)",
    description: "Percentual máximo de uma reserva que pode ser pago com a carteira do usuário",
    type: 'porcen'
  },
}

export const SettingsList: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [loadingSave, setLoadingSave] = useState(false)

  const { api, user } = useApi();
  const { notify, theme } = useGlobal();

  const form_ref = useRef<IGenericFormRefProps>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    setValue,
    trigger,
  } = useForm({
    defaultValues: ({} as any),
    mode: "all",
  });

  const _form = watch();

  const searchSettings = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/constants");
      for (const key of Object.keys(data)) {
        const { type } = CONSTANTS_MAPPER[key]
        let value = data[key]
        if (type === 'porcen') value = maskFunctions.porcen.mask(value * 100) + ' %'
        setValue(key, value)
      }
    } catch (error) {
      console.log(error)
      notify("Erro ao buscar as configurações", 'error');
    }
    setLoading(false);
  }, [api])

  const onSubmit = useCallback(async () => {
    setLoadingSave(true);

    try {
      const form = form_ref.current?.getForm();

      for (const key of Object.keys(form ?? {})) {
        const { type } = CONSTANTS_MAPPER[key]
        let value = Number(form[key])
        if (type === 'porcen') value = value / 100
        form[key] = value
      }

      await api.put("/constants", form);

      notify('Configurações atualizadas com sucesso!', 'success');

    } catch (err: any) {
      console.log(err)
      const error = err.response ? err.response?.data : "SERVER ERROR";
      if (error.field)
        setError(error.field, { type: "manual", message: error.detail });
      else notify("ERRO INTERNO DO SISTEMA", "error");
    }

    setLoadingSave(false);
  }, [form_ref, _form, api, searchSettings]);

  const handleStopPropagation = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e?.stopPropagation();
      handleSubmit(onSubmit)(e);
    },
    [handleSubmit, onSubmit, form_ref, _form, api]
  );

  const groups: IGroupProps[] = [
    {
      name: "",
      label: "",
      fields: [
        Object.entries(CONSTANTS_MAPPER).map(([key, { label, description, type }]) => ({
          name: key,
          label,
          type: "input",
          mask: type,
          required: true,
          tooltip: description,
        }) as IFieldProps)
      ],
    },
  ];

  useEffect(() => {
    searchSettings()
  }, [])

  return (
    <DashboardPage>
      <ContentOrLoading loading={loading} theme={theme}>
        <Column style={{ flex: 1, paddingTop: 20 }}>
          <ContainerForm open>
            <Form onSubmit={handleStopPropagation}>
              <GenericForm
                ref={form_ref}
                groups={groups}
                _form={_form}
                control={control}
                trigger={trigger}
                errors={errors}
                setValue={setValue}
                register={register}
                disabled={loadingSave || !user.user_type.permissions.includes('SETTINGS-UPDATE')}
              />
              <button
                className="display-none"
                type="submit"
                ref={submitButtonRef}
              />
            </Form>
          </ContainerForm>
        </Column>
        {user.user_type.permissions.includes('SETTINGS-UPDATE') && (
          <ContainerButtons style={{ marginBottom: -10, marginTop: 40 }} theme={theme}>
            <Button
              onClick={() => submitButtonRef.current?.click()}
              background={secondary[theme]}
              color={"#FFF"}
              style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
              children={"Salvar"}
            />
          </ContainerButtons>
        )}
        <ModalLoading loading={loadingSave} theme={theme} />
      </ContentOrLoading>
    </DashboardPage>
  );
};
