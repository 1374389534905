import './style.css';
import Button from "../../../Buttons/Button";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { secondary } from "../../../../theme";
import { useApi } from "../../../../hooks/api";
import { WppSimulator } from '../WppSimulator';
import { useGlobal } from "../../../../hooks/global";
import { IOptionsProps } from "../../../../interfaces";
import { Column, Label, Line } from "../../../../style";
import { ModalLoading } from "../../../Loadings/ModalLoading";
import { ContainerButtons, ContainerForm, Form, VariableButton } from "./style";
import { FixRequiredSelect } from "../../../Inputs/BaseSelects/FixRequiredSelect";
import { Editor } from '@tinymce/tinymce-react';
import Input from '../../../Inputs/Input';
import { MaskPhone } from '../../../../services/maskServices';

interface IFormSendWppMessageModalProps {
  onCancel: Function;
  onSave: Function;
  options: IOptionsProps[];
  defaultData?: {
    id: string;
    title: string;
    subject: string;
    type: string;
    text: string;
    name: string;
  } | null;
  phones: string[];
  variables: any;
}

const ICONS_MAPPER: any = {
  reservation: '🏨',
  accommodation: '🏠',
  'check-in': '🔑',
  guest: '🧑',
  general: '📅'
}

function htmlToWhatsappText(html: any) {
  // Cria um elemento temporário para converter HTML para texto
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;

  // Função para converter entidades HTML
  function decodeHtmlEntities(text: any) {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = text;
    return textarea.value;
  }

  // Função para converter HTML para texto formatado para WhatsApp
  function traverseNodes(node: any) {
    let text = '';

    if (node.nodeType === Node.TEXT_NODE) {
      return decodeHtmlEntities(node.nodeValue);
    }

    for (let child of node.childNodes) {
      text += traverseNodes(child);
    }

    switch (node.nodeName) {
      case 'P':
        return text + '\n\n'; // Adiciona duas quebras de linha para novos parágrafos
      case 'BR':
        return text + '\n'; // Adiciona uma quebra de linha para <br/>
      case 'STRONG':
      case 'B':
        return '*' + text + '*'; // Negrito
      case 'EM':
      case 'I':
        return '_' + text + '_'; // Itálico
      case 'U':
        return '~' + text + '~'; // Riscado (underline não suportado no WhatsApp)
      case 'H1':
      case 'H2':
      case 'H3':
        return '\n*' + text + '*\n'; // Cabeçalhos tratados como negrito
      case 'UL':
      case 'OL':
        // Listas não são suportadas diretamente, mas podemos formatar manualmente
        return text.split('\n').map(item => '• ' + item).join('\n');
      case 'LI':
        return '\n• ' + text;
      default:
        return text;
    }
  }

  return traverseNodes(tempDiv).trim();
}

const VARIABLES = [
  { label: 'Código da Reserva', value: 'reservaCodigo', type: 'reservation' },
  { label: 'Data de Entrada', value: 'reservaDataEntrada', type: 'reservation' },
  { label: 'Data de Saída', value: 'reservaDataSaida', type: 'reservation' },
  { label: 'Status de Pagamento', value: 'reservaStatusPagamento', type: 'reservation' },
  { label: 'Status de Check-in', value: 'reservaStatusCheckin', type: 'reservation' },
  { label: 'Origem da Reserva', value: 'reservaOrigem', type: 'reservation' },
  { label: 'Data de Criação da Reserva', value: 'reservaDataCriacao', type: 'reservation' },
  { label: 'Data de Criação do Check-in', value: 'checkinDataCriacao', type: 'check-in' },
  { label: 'Senha da Acomodação', value: 'checkinSenhaAcomodacao', type: 'check-in' },
  { label: 'Senha da Área de Acesso', value: 'checkinSenhaAreaAcesso', type: 'check-in' },
  { label: 'Código da Acomodação', value: 'acomodacaoCodigo', type: 'accommodation' },
  { label: 'Nome da Acomodação', value: 'acomodacaoNome', type: 'accommodation' },
  { label: 'Localização da Acomodação', value: 'acomodacaoLocalizacao', type: 'accommodation' },
  { label: 'Tipo de Acesso da Acomodação', value: 'acomodacaoTipoAcesso', type: 'accommodation' },
  { label: 'Fechadura da Acomodação', value: 'acomodacaoFechadura', type: 'accommodation' },
  { label: 'Áreas de Acesso da Acomodação', value: 'acomodacaoAreasAcesso', type: 'accommodation' },
  { label: 'Primeiro nome do hóspede titular', value: 'hospedePrimeiroNomeTitular', type: 'guest' },
  { label: 'Nome do hóspede titular', value: 'hospedeNomeTitular', type: 'guest' },
  { label: 'E-mail do hóspede titular', value: 'hospedeEmailTitular', type: 'guest' },
  { label: 'CPF do hóspede titular', value: 'hospedeCPFTitular', type: 'guest' },
  { label: 'Passaporte do hóspede titular', value: 'hospedePassaporteTitular', type: 'guest' },
  { label: 'Telefone do hóspede titular', value: 'hospedeTelefoneTitular', type: 'guest' },
  // { label: 'Links dos documentos do hóspede titular', value: 'hospedeLinksDocsTitular', type: 'guest' },
  { label: 'Todos os dados dos hóspedes', value: 'hospedeTodos', type: 'guest' },
  { label: 'Data de hoje', value: 'hoje', type: 'general' },
  { label: 'Dia atual', value: 'diaAtual', type: 'general' },
  { label: 'Dia atual por extenso', value: 'diaAtualExtenso', type: 'general' },
  { label: 'Mês atual', value: 'mesAtual', type: 'general' },
  { label: 'Mês atual por extenso', value: 'mesAtualExtenso', type: 'general' },
  { label: 'Ano atual', value: 'anoAtual', type: 'general' },
  { label: 'Ano atual por extenso', value: 'anoAtualExtenso', type: 'general' },
]

export const FormSendWppMessage: React.FC<IFormSendWppMessageModalProps> = (props) => {
  const { onSave, options, defaultData, variables } = props;

  const { theme, notify, notifyBottom } = useGlobal();
  const { api } = useApi();
  const [visualizationMode, setVisualizationMode] = useState('preview');
  const [message, setMessage] = useState(defaultData?.text ?? "");
  const [selectedMessageModel, setSelectedMessageModel] = useState<any>(options?.[0]?.value);
  const [phone, setPhone] = useState(props.phones?.[0]);
  const [customVariables, setCustomVariables] = useState<any[]>(VARIABLES.map((v) => ({ ...v, text: variables[v.value] ?? '' })));
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const [loadingCreate, setLoadingCreate] = useState(false);

  const onSubmit = useCallback(async () => {
    setLoadingCreate(true);

    try {
      window.open(`https://api.whatsapp.com/send?phone=+55${phone}&text=${encodeURIComponent(htmlToWhatsappText(message))}`, '_blank');

      onSave();
    } catch (err: any) {
      const message = err?.response?.data?.detail ?? "Erro ao criar link da mensagem";
      notify(message, "error");
    }

    setLoadingCreate(false);
  }, [message, phone, api]);

  const handleStopPropagation = useCallback(
    (e: any) => {
      e?.preventDefault();
      e?.stopPropagation();
      const confirm = window.confirm("Deseja realmente enviar a mensagem?");
      if (confirm) onSubmit();
    },
    [onSubmit, api]
  );

  const copyText = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        notifyBottom("Variável copiada para área de tranferência!", "success");
      })
      .catch((err) => {
        notifyBottom("Erro ao copiar a variável para a área de tranferência!", "error");
      });
  };

  const handleEnableEdition = useCallback(() => {
    const result = window.confirm("Deseja realmente habilitar a edição do texto?");
    if (result) setVisualizationMode('text');
  }, [])

  useEffect(() => {
    const findedModel = options.find((item) => item.value === selectedMessageModel);
    if (findedModel) setMessage(findedModel?.this?.text)
  }, [selectedMessageModel])

  useEffect(() => {
    let newMessage = `${message}`;
    for (const variable of customVariables) {
      if (newMessage.includes(`{{${variable.value}}}`)) {
        newMessage = newMessage.replaceAll(`{{${variable.value}}}`, variable.text.replaceAll('\n', '<br/>'));
      }
    }
    if (newMessage !== message) setMessage(newMessage);
  }, [message])

  return (
    <>
      <Line style={{ flex: 1, overflow: "auto", flexDirection: "row", gap: 16, padding: 16, flexWrap: 'wrap' }}>
        <ContainerForm open style={{ flex: 4, paddingBottom: 0 }}>
          <Form onSubmit={handleStopPropagation}>
            <Column style={{ gap: 16, flex: 1 }}>
              <Column style={{ gap: 16 }}>
                <Line style={{ gap: 16, flexWrap: "wrap" }}>
                  <Column style={{ flex: 10 }}>
                    <Label>Modelo*</Label>
                    <FixRequiredSelect
                      placeholder=""
                      options={options}
                      value={options.find((item) => item.value === selectedMessageModel)}
                      onChange={(value: any) => setSelectedMessageModel(value?.value)}
                    />
                  </Column>
                  <Column style={{ flex: 10 }}>
                    <Label>Telefone*</Label>
                    <Input
                      placeholder=""
                      defaultValue={phone}
                      onChange={(value: any) => setPhone(value)}
                      required
                    />
                  </Column>
                  {visualizationMode === 'preview' && (
                    <Column style={{ alignItems: "flex-end", justifyContent: "flex-end", flex: 1 }}>
                      <Button
                        onClick={handleEnableEdition}
                        background={secondary[theme]}
                        color={"#FFF"}
                        style={{ padding: 8, maxWidth: 150, fontSize: 14, whiteSpace: "nowrap" }}
                      >
                        Habilitar edição
                      </Button>
                    </Column>
                  )}
                </Line>
              </Column>
              <Line style={{ flexWrap: "wrap", gap: 16 }}>
                {visualizationMode === 'text' && (
                  <Column style={{ minWidth: 300, flex: 4, minHeight: 500 }}>
                    <Editor
                      apiKey="bypgaef6jor1xklepivwa1iftz9u5o1bhm004dd1dvo7tkx2"
                      value={message}
                      init={{
                        height: '100%',
                        width: '100%',
                        language: 'pt_BR',
                        menubar: false,
                        end_container_on_empty_block: true,
                        plugins: "fullscreen paste",
                        toolbar: 'bold italic strikethrough | fullscreen',
                        resize: false,
                      }}
                      onEditorChange={(value: string) => setMessage(value)}
                    />
                  </Column>
                )}
                <Column style={{ flex: 1, justifyContent: "center", alignItems: "center", width: "100%" }}>
                  <WppSimulator text={message} />
                </Column>
              </Line>
            </Column>
            <button
              className="display-none"
              type="submit"
              ref={submitButtonRef}
            />
          </Form>
        </ContainerForm>
        <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
          <div style={{ display: "flex", flexDirection: "column", gap: 10, minWidth: 300, flex: 1 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ fontWeight: "bold" }}>Variáveis</div>
              <div style={{ fontSize: 14 }}>
                Clique em uma variável para copiar a chave correspondente.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 10, padding: 10, marginLeft: -10 }}>
              {customVariables.map((v) => (
                <VariableButton onClick={() => copyText(v.text)} key={v.value} theme={theme}>
                  {ICONS_MAPPER[v.type]} {v.label}
                </VariableButton>
              ))}
            </div>
          </div>
        </div>
      </Line>
      <ContainerButtons theme={theme}>
        <Button
          onClick={() => submitButtonRef.current?.click()}
          background={secondary[theme]}
          color={"#FFF"}
          style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
          children={"Enviar mensagem"}
        />
      </ContainerButtons>

      <ModalLoading loading={loadingCreate} theme={theme} />
    </>
  );
};
